import React from 'react';

export const Paris = {
    "name": "Paris",
    "link": "paris",
    "country": "France",
    "continent": "Europe",
    "thumb": "https://live.staticflickr.com/65535/50379604033_e507c8e7ee_t.jpg",
    "image": "https://live.staticflickr.com/65535/50379604033_1fd6913b59_o.jpg",
    "caption": "The City of love",

    "description": "Paris. The city of love. Poets, artists, playwrights, writers, journalists, and more have all written about the inspiration they got from this city and it’s easy to understand them. It’s a place where you can get lost, where you can explore a sea of culture, art, sophistication, class, and style. Wander in and out of museums, cafés, farmer markets, beautiful gardens and enjoy the romance of the city.  Paris is gigantic, with thousands of years of history. ",

        "nrDays":<p>
                Paris is a huge city, and <b>3 days</b> is the bare minimum to search and enjoy what the city has to offer.
            We recommend you spend at least <b>4 days</b> here, in order to visit and embrace all the main attractions, taste the French cuisine, live the vibrant night life and visit Versailles.
                <br/><br/>
                You should aim to spend up to <b>5 or 6 days</b> in the city if you want to visit the <a href="https://www.disneylandparis.com/en-us/" target="_blank" rel="noopener noreferrer">Disneyland</a>, depending on how much days you would like to spend there.
            </p>,

    "whenVisit": <p>
            The best time to visit the city is far away from the hot season when it’s all full of tourist. We recommend to visit in <b>May or September/October</b>, when the weather is good and there are fewer crowds. The prices for accommodation tend to be a little lower during these months.
            <br/><br/>
            During the winter the city is really cold and the probability of rain is big. However Paris is also a beautiful city during this time of the year and if you don’t have any problem with the cold and rain, <b>December</b> is a beautiful time to see the magical lights of Christmas and to take a trip to the <a href="https://www.disneylandparis.com/en-us/" target="_blank" rel="noopener noreferrer">Disneyland</a>.
        </p>,

    "mobility": <p>
        The Paris public transport system is one of the most developed and efficient in the world. In every corner you will find a <a href="https://parisbytrain.com/paris-metro-maps/" target="_blank" rel="noopener noreferrer">metro (subway) stop</a>.  Attached to that you also have the <a href="https://parisbytrain.com/paris-rer-map/" target="_blank" rel="noopener noreferrer">RER Train</a>.
        The RER is an above-ground train (deeper than metro) and has five lines serving Paris and Île-de-France. It works equal as the metro and you can use the same tickets for it. If you have a connecting journey with a metro, you can use the same ticket.
        The difference between them is that you need to validate your ticket on your way out of RER. The bus works with the same ticket system and has 64 lines connecting all the city. As if all this was not enough, you also have four tram lines in the city’s perimeter.
        Taxi and Uber are other options, but more expensive. You can opt for a bike-sharing too, like Velib’.
        <br/><br/>
        Regarding the tickets you can opt by one of the next three option depending which type is more convenient for you:
        <br/><br/>
         <ul>
            <li>A single ticket (t+ ticket) is the name given to the single journey transport ticket. It can be used for all the transportation systems (RER inside Paris) and costs 1,90€ if you buy at the machines or 2€ at the bus. A “carnet” of 10 single-use tickets costs about 15€. Each ticket is valid for 90 minutes.
                The <b>exceptions</b> for this ticket are the zone 1 for RER (outside Paris) and the tram T4. It’s not possible to change from the metro to the bus or use the same bus line twice with the same ticket.</li>
            <br/>
            <li><a href="https://www.ratp.fr/en/titres-et-tarifs/paris-visite-travel-pass" target="_blank" rel="noopener noreferrer">The Paris Visit Pass</a> (more useful for a <b>couple of days</b> in the city) allows unlimited use of the transport system in Paris. Included also are some discounts on Paris attractions, including the Opéra and a sightseeing cruise.
                You can choose your validate period from 1 to 5 days. This period starts at midnight of the first day and it ends at midnight of the last day. You can purchase the Paris Visit pass for two different zones: 1-3 (enough for the centre of Paris) or 1-5 (useful if you will use the public transportation to go to the airports of Charles de Gaulle or Orly and to the Disneyland).
                You can find the fare options bellow (children from 4 to 11):</li>
            <br/>
            <table style={{ "width": "100%" }}>
                <tr>
                    <th>Validity</th>
                    <th>Adults Zones 1-3</th>
                    <th>Adults Zones 1-5</th>
                    <th>Children Zones 1-3</th>
                    <th>Children Zones 1-5</th>
                </tr>
                <tr>
                    <td>1 Day</td>
                    <td>12€</td>
                    <td>25.25€</td>
                    <td>6€</td>
                    <td>12.60€</td>
                </tr>
                <tr>
                    <td>2 Days</td>
                    <td>19.50€</td>
                    <td>38.35€</td>
                    <td>9.75€</td>
                    <td>19.15€</td>
                </tr>
                <tr>
                    <td>3 Days</td>
                    <td>26.65€</td>
                    <td>53.75€</td>
                    <td>13.30€</td>
                    <td>32.90€</td>
                </tr>
                <tr>
                    <td>5 Days</td>
                    <td>38.35€</td>
                    <td>65.80€</td>
                    <td>19.15€</td>
                    <td>32.90€</td>
                </tr>
            </table>
            <br/>
            <li><a href="https://parisbytrain.com/paris-train-metro-week-pass-navigo-decouverte/" target="_blank" rel="noopener noreferrer">The Pass Navigo</a> is the best option to use if you are staying in the city for 3 days or more. It can be used in all transportation systems and the card can be purchased for 5€. This card can also be used for the Charles de Gaulle and Orly airports, Disneyland and Versailles <b>(choosing the 5 zones pass, recommended by us)</b>.
                You will need to bring with you a 3x4 photo. The only disadvantage of this card is the validation period. You can purchase it weekly or monthly. <b>The weekly pass Navigo is valid from Monday 0h01 am to Sunday 11h59 pm</b>. That means that if you’re going to visit Paris from a Saturday to a Tuesday you will need to charge it with two weeks for full use of the pass. In that case you will need to analyze which option is the more convenient for you. The Passe Navigo fares are described below:
            </li>
            <br/>
            <table style={{ "width": "100%" }}>
                <tr>
                    <th>Zones</th>
                    <th>Weekly</th>
                    <th>Monthly</th>
                </tr>
                <tr>
                    <td>All Zones</td>
                    <td>22.80€</td>
                    <td>75.20€</td>
                </tr>
                <tr>
                    <td>Zones 2-3</td>
                    <td>20.85€</td>
                    <td>68.60€</td>
                </tr>
                <tr>
                    <td>Zones 3-4</td>
                    <td>20.20€</td>
                    <td>68.60€</td>
                </tr>
                <tr>
                    <td>Zones 4-5</td>
                    <td>19.85€</td>
                    <td>66.20€</td>
                </tr>
            </table>
        </ul>
        <br/>
        As Paris is very well connected with the rest of the world it’s easy to get to the city by plane, train, bus or car. If you choose the option of the plane, take into account that these referred passes are only valid for the Charles de Gaulle and Orly Airports.
        Beauvais Airport is more dislocated from the city centre (around 80km) and you will need to take a train, bus or car-sharing to come to the city.
    </p>,

    "safety": <p>Paris is generally a <b>safe place</b> and the risk of violent crime is very low. As is usual in big cities, <b>pick-pocketing</b> is the biggest issue here, mostly happening in public transportation. Gare du Nord, Stalingrad, Jaures and Les Halles are neighborhoods that you should <b>avoid</b> at night.
        <br/><br/>
        People here usually are friendly and helpful, and unlikely to get you in trouble. However never leave your personal belongings far from you at the streets and parks.
    </p>,

    "itinerary": {
        "description": "Paris is so big that you will feel that four days will not be enough to visit all you want. Don’t forget to use the metro between your stops, it’s the easiest and fastest way to move around and will save you a lot of time. If you have extra-days use it to visit the Disneyland Park or to embrace the beauty of all Paris museums. Here is a quick itinerary for four days in Paris.",
        "days": [
            {
                "description": <p>
                    For the first day you can start with a visit to the magnifique <b>Museum Du Louvre</b>. Your time here will depend
                    on how much of an art lover you are. We recommend at least 3 to 4 hours to see the major points (if you are a
                    truly enthusiastic you will need an entire day or more). For a quick entry on the museum, head to the shopping centre
                    “Carrousel du Louvre” from Rue de Rivoli. A lot of people don’t know about it and you can skip most of the entrance lines.
                    After your visit to the museum take a look the <b>“Pyramide du Louvre”</b>.
                        <br/><br/>
                    Take a quick lunch at the area and relax in <b>Jardin des Tuileries</b> after to recover some energy. Then,
                    cross the river to meet the gargoyles and chimera at the <b>Cathédrale Notre-Dame</b> de Paris. Believe it or not, it is the
                    most visited monument in the city. Continue your path to the <b>Place de la Concorde</b> and take a look on the most
                    beautiful bridge in Paris, <b>Alexandre III Bridge</b>. After that, go straight in the <b>Champs-Élysées Avenue</b> until you reach the <b>Arc de Triomphe</b>.
                    Make a quick stop at the area to try the famous <b>Macaron</b>. Here you can climb at the sunset and
                    have the first view from the entire Paris. To finish your day you can have a nice dinner at the surroundings of the <b>Latin Quarter</b> and enjoy the night life there.
                    </p>,
                "image": "https://live.staticflickr.com/65535/50497816653_1c97355bf1_o.jpg",
                "caption": "Museum du Louvre."
            },
            {
                "description": <p>
                        Your second day is saved for the most famous monument of the city. Start at <b>Palais de Chaillot (Trocadero)</b> and walk across the beautiful bridge <b>Pont de Bi-Hakeim</b>.
                        Then, you will be on your way to climb the <b>Eiffel Tower</b> and be embraced for the magnifique view. Grab a few croissants and have breakfast on the green grass before heading to the ticket office.
                        After that grab a salad, pizza or baguette and enjoy the beauty of the <b>Luxembourg Gardens</b>.
                        The Gardens are beautifully landscaped with hundreds of statues, monuments (including the first model of the Statue of Liberty) and fountains.
                        <br/><br/>
                        If you have time before dinner for both, make a stop at the <b>Panthéon</b> and at
                        the <b>Galeries Lafayette</b>. At dinner we recommend enjoying a nice <b>Bateaux Parisiens Seine River Gourmet Dinner</b>.
                        You will have a lot of different boats to choose from, and it will all depend on how much you want to spend, but the route is almost the same for everyone.
                        The cruise dinner lasts about 2 to 3 hours, and there will be all sorts of entertainment during the experience.
                    </p>,
                "image": "https://live.staticflickr.com/65535/50498679817_5fb57e72d1_o.jpg",
                "caption": "Eiffel Tower."
            },
            {
                "description": <p>
                At the 3rd day we recommend taking the RER to visit the magnifique <b>Palace of Versailles</b> and all the gardens. You will certainly need to spend a lot of hours watching the magnifique interior and the miles and miles of gardens.
                For the gardens we recommend you bring lunch with you and relax there. Grab a bike to have the opportunity to see as much as you can. For dinner why not try some restaurant at Versailles before making your journey back to the city.
                </p>,
                "image": "https://live.staticflickr.com/65535/50498679777_22b4fb4b63_o.jpg",
                "caption": "Gardens at the Palace of Versailles."
            },
            {
                "description": <p>
                    For your last day you should start to visit the <b>Montmartre</b>. Visit the <b>Basilica of Sacre-Coeur</b> and taste the street food here (try some traditional baguette).
                    Then make your way through all the narrow streets here until you reach the <b>Moulin Rouge</b>, the most famous cabaret of Paris. Then check out the <b>Wall of Love</b> and finish your day with a visit to other Museums, such as <b>Orsay or Picasso’s</b>.
                </p>,
                "image": "https://live.staticflickr.com/65535/50498529771_688a78de5a_o.jpg",
                "caption": "Basilica of Sacre-Coeur."
            }
        ]
    },

    "highlights": [
        {
            "name": "Eiffel Tower",
            "description": <p>This is a <b>must</b> visit place on your first trip to Paris. The first view of the tower will certainly be out breathing for you. After embracing the magnitude of the building and taking the best picture, you can choose to go up using the stairs or the elevator. Keep in mind that there are 1665 steps to climb. The <a href="https://www.toureiffel.paris/en/rates-opening-times" target="_blank" rel="noopener noreferrer"> ticket price</a> vary if you want to go until the 2nd floor or to the top. If you want to go until the 2nd floor the prices vary from 10 to 16€ (stairs vs elevator). If you want to go until the top the prices go from 19 to 26€. Personally we recommend you go to the top. It has an amazing view, and you will not regret it.</p>,
            "image": "https://live.staticflickr.com/65535/50497816523_50e316ec37_o.jpg"
        },
        {
            "name": "Louvre Museum",
            "description": <p>The Louvre Museum is the country's most renowned museum and one of the most visited in the world. It has a permanent collection of nearly 300000 works, including <b>The Mona Lisa</b> and <b>Venus de Milo</b>. Due to the size of the museum consider taking at least 3 to 4 hours to see all the pieces. The price for the museum is 15€ but under 18 and EU citizens under 25 don't pay. </p>,
            "image": "https://live.staticflickr.com/65535/50498529671_845cd10007_o.jpg"
        },
        {
            "name": "Notre Dame Cathedral",
            "description": "This Cathedral, dedicated to Virgin Mary, is one of the oldest Gothic Cathedrals in the world. On the top there is an amazing view from Paris. It is being rebuilt after after the fire of 2019, and at the moment it is not open to the public, but the entrance fee used to be 8,5€ (free for under 18 and EU citizens under 24) for the towers and 7€ to the crypts.",
            "image": "https://live.staticflickr.com/65535/50497816488_f42f8e6221_o.jpg"
        },
        {
            "name": "Arc de Triomphe",
            "description": "The Arc de Triomphe has an emblematic value for the capital of France as it represents some of the victories of the French army under the reign of Napoleon. It stands 50m tall and the entrance fee is around 12€ (free for under 18).",
            "image": "https://live.staticflickr.com/65535/50498529656_510df5a95f_o.jpg"
        },
        {
            "name": "Jardin du Luxembourg",
            "description": "The Luxembourg Garden is the most centric and popular park in Paris. It has a great view to the Luxembourg Palace and the environment overflowed with flowers and trees will give you a magnific relax time.",
            "image": "https://live.staticflickr.com/65535/50497816473_40bf9d8582_o.jpg"
        },
        {
            "name": "Disneyland Park",
            "description": <p>The Disneyland park and the Walt Disney Studios are two of the most famous attractions in the surroundings of
                Paris. Taking the RER A (Red Line) at the city centre takes around 1 hour to arrive to the park. The <a href="https://www.disneylandparis.com/en-gb/tickets/1-day-ticket/" target="_blank" rel="noopener noreferrer">entrance fee</a> will depend if you choose one or the two parks and on how many days you will spend there.
                During the Christmas season the park offers some parades that will leave the children amazed.</p>,
            "image": "https://live.staticflickr.com/65535/50497816463_25ca3b6265_o.jpg"
        },
        {
            "name": "The Montmartre",
            "description": <p>Also known as the painter's neighborhood, the narrow streets are home to the oldest cabarets, like the <b>Moulin
            Rouge</b>, the most famous cabaret in Paris, and to the <b>Basilica of the Sacré-Coeur</b>. This place was an independent commune
            outside Paris until 1860, but now is the eighteenth district of the city. It has a crowded night life mostly due to all the cabarets. It
            has a funicular that joins the narrow streets to the top of the hill where the Basilica stands. Your navigo pass also works on this funicular.</p>,
            "image": "https://live.staticflickr.com/65535/50498679587_a1d8852c2f_o.jpg"
        },
        {
            "name": "Palace of Versailles",
            "description": <p>This Palace is an imposing royal palace with a magnificent architecture and endless gardens built during the reign of
                King Louis XIV. The <b>Château de Versailles</b> combines history and culture and is one of the most popular spots on the country.
                The hall of mirrors is famous in almost every parts of the world. To visit all the gardens by foot is impossible in one day, so we recommend to spend the time in some of the nearest lakes or rent a bike to explore everything.
                The entrance fee is 20€ (free to under 18 and EU citizens under 25). To get to Versailles take the RER C from the city centre.
            </p>,
            "image": "https://live.staticflickr.com/65535/50498679582_f0e9e2f83b_o.jpg"
        },
        {
            "name": "The Pantheon",
            "description": "The Pantheon was the first major monument in Paris. Famous by is neoclassical facade and its impressive interior, this is worth place to visit. The entrance fee is 9€ and free for under 18. ",
            "image": "https://live.staticflickr.com/65535/50498679537_b0dedf7652_o.jpg"
        },
        {
            "name": "Latin Quarter",
            "description": "The Latin Quarter is located in the south of the Île de la Cité and is one of the liveliest and busiest areas of Paris. The streets of this neighborhood are full of reasonably-priced restaurants and cafés with terraces. ",
            "image": "https://live.staticflickr.com/65535/50497816393_9bf021413c_o.jpg"
        },
        {
            "name": "Jardin des Tuileries",
            "description": "Situated between the Louvre Museum and the Place de la Concorde, the Tuileries Garden was the first garden in Paris opened to the public. It is currently one of the most popular parks in Paris.",
            "image": "https://live.staticflickr.com/65535/50498529516_a4e7045e75_o.jpg"
        },
        {
            "name": "Place Vendôme",
            "description": <p>The Place Vendôme is one of the city’s most famous and magnificent <b>neoclassical squares</b>. Surrounded by amazing, sober buildings, it has inside some of the most famous high-end stores, like, Dior, Chanel or Cartier.</p>,
            "image": "https://live.staticflickr.com/65535/50497816353_4b5d10f104_o.jpg"
        },
        {
            "name": "Musée d’Orsay",
            "description": "Sure, it is not as famous as the Louvre, but the d’Orsay shouldn’t be missed. It’s placed in an old art nouveau train station and it contains an impressive collection — including one of the best collections of impressionist paintings from Renoir, Monet, Van Gogh, and others. ",
            "image": "https://live.staticflickr.com/65535/50498529461_9cfa0ea118_o.jpg"
        },
        {
            "name": "Lafayette Galleries",
            "description": "This department store is located in the middle of the city and has a top floor that offers great city views. It doesn't have entrance fee.",
            "image": "https://live.staticflickr.com/65535/50498679392_aa5300010e_o.jpg"
        },
        {
            "name": "Paris Catacombes",
            "description": "As Paris expanded the city realized that cemeteries might not be the best use of space. Because of that, they decided to dug up the bones of nearly 7-millions Parisians and arranged them inside the city’s many miles of underground quarry tunnels. And it's possible to take a tour of these piles of bones. Yeah, little bizarre and creepy but it’s a very common attraction here.",
            "image": "https://live.staticflickr.com/65535/50497816263_0a49e0cde8_o.jpg"
        }
    ],

    "food": {
        "description": <p>
            You could climb up all the stops of the Eiffel Tower, walk the long avenue of the Champs-Elysées and spend hours searching in the Louvre, but if you don’t eat out in Paris, you’ll have missed out entirely.
            Food is a central part of French culture. So much so, that in fact, UNESCO declared the French way of eating as Intangible Cultural Heritage in 2010!
            <br/><br/>
            For a cheap meal you can always look for a good restaurant on <a href="https://www.thefork.com/" target="_blank" rel="noopener noreferrer"> The Fork. </a>If you are searching for the best dishes, we give you an idea of what to sample.
            </p>,

        "restaurants": [
            {
                "name": "Pierre Hermé Bonaparte",
                "description": "It's not cheap, but if you are a fan of sweets, you will taste here one of the best macaron of the city. Famous by its croissants too.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50497816238_0bff958e06_o.jpg"
            },
            {
                "name": "Bistrot des Vosges",
                "description": "You really should taste here the amazing onion soup. With a perfect flavor, you will not be disappointed. It has other traditional cuisine options.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50498529341_7cc3c235df_o.jpg"
            },
            {
                "name": "La Maison Pichard",
                "description": "A family business for over 20 years, Maison Pichard is the ideal place to go for a traditional, yet memorable croissant.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50497816208_42119717af_o.jpg"
            },
            {
                "name": "Maison Castro",
                "description": "Baguette sandwiches with a Mediterranean twist — featuring meats and cheeses from France, Italy, and Spain. A nice choice if you want to try something different than the standard sandwich.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50497816198_3c225a7450_o.jpg"
            },
            {
                "name": "Il Etait Un Square",
                "description": "Very famous for its burger, it is one of the best places on the city for it. The prices are moderate inside the \"normal\" in Paris",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50498679277_4a3d3f9662_o.jpg"
            },
            {
                "name": "Bateaux Parisiens Seine Rive Cruise",
                "description": "You will have the opportunity to dinner with a view for the Eiffel Tower, Notre-Dame Basilica and others Paris attractions. You can taste here some traditional French dishes.",
                "price": 4,
                "image": "https://live.staticflickr.com/65535/50498679262_d85c9afb60_o.jpg"
            },
            {
                "name": "L'Aubergeade",
                "description": "Dish after dish, the food here is excellent and the staff is very friendly and efficient. The place has a good environment.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50497816148_ef9f7aa2e5_o.jpg"
            },
            {
                "name": "Boutary",
                "description": "In the heart of the Latin Quarter, this expensive but fantastic restaurant will give you some delicious options of the traditional chic French cuisine.",
                "price": 4,
                "image": "https://live.staticflickr.com/65535/50498529251_851b04ae40_o.jpg"
            }
        ]
    },

    "stay": {
        "description": <p>
                Paris is one of the cities with the greatest number of hotels in Europe. In a one-mile radius you can find over one thousand hotels.
                So, finding a place to stay is not difficult, however the prices are not always what you are hoping for.
                Personally, we recommend finding accommodations on both sides of the Seine, from the Île de la Cité to the Eiffel Tower. These areas are safe, central and ideal to stay in.
                If you don't have problems with spending a little more time in the metro and want to spend less on housing there are a variety of cheaper hotels in the suburbs.
            <br/><br/>
            Below we give you some suggestions of places to stay for every budget.
        </p>,

        "budget": [
            {
                "name": "MEININGER Hotel Paris Porte de Vincennes",
                "description": "Not in the city centre but with nice services and accommodations, this place is a \"budget\" option inside the Paris standards. ",
                "image": "https://live.staticflickr.com/65535/50498529226_291173c2db_o.jpg",
                "link": "/"
            },
            {
                "name": "Hôtel Avenir Jonquière",
                "description": "A little dislocated too, but with a metro stop just outside and close to the Montamartre area this is also a good choice for your stay.",
                "image": "https://live.staticflickr.com/65535/50497816123_f012fd9f4f_o.jpg",
                "link": "/"
            }
        ],
        "mid": [
            {
                "name": "Elysées Ceramic",
                "description": "Close to the Arc de Triomphe and with an amazing staff and very clean rooms, this is a good place for your stay in the city.",
                "image": "https://live.staticflickr.com/65535/50497816113_0a4f59826c_o.jpg",
                "link": "/"
            },
            {
                "name": "Elysées Union",
                "description": "Located at a 15 minutes walk from the Eiffel Tower this amazing hotel is a perfect choice for a Mid-Range Budget. It is great for groups too. ",
                "image": "https://live.staticflickr.com/65535/50498679137_bd903fcd97_o.jpg",
                "link": "/"
            }
        ],
        "high": [
            {
                "name": "Résidence Charles Floquet",
                "description": "Great and clean apartments, located at the Champ de Mars, it is a perfect spot for a very comfortable stay.",
                "image": "https://live.staticflickr.com/65535/50497816083_9ecfdd8aef_o.jpg",
                "link": "/"
            },
            {
                "name": "Hôtel Montecristo",
                "description": "A luxurious hotel located close to Le Jardin du Luxembourg with big rooms and an interior swimming pool.",
                "image": "https://live.staticflickr.com/65535/50497816073_7e561aca48_o.jpg",
                "link": "/"
            }
        ]
    }
}
