import React from 'react';
import CityGuide from '../../../components/guides/CityComponent';
import Footer from '../../../components/FooterComponent';

import { Paris } from '../../../guides/countries/france/paris';

function ParisPage(props) {
    return (
        <div>
            <CityGuide city={ Paris } pathname={ props.location.pathname } urlBack="/travel-guides/europe" />
            <Footer />
        </div>
    );
}

export default ParisPage;
